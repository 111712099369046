import React from "react";
import { MdArrowCircleRight } from "react-icons/md";
import heroImage from "../assets/hero.png";
// ------------------------------- Component Contents -------------------------------
const heading = "make your fashion look more charming";
const subHeading =
  "trendy collections for your fashion look. we have a wide range of products for you.";
const cta = "Learn More";
const phone = "+977-9823578866";
// ------------------------------- Component Contents Ends-------------------------------
function Hero() {
  return (
    <section className="bg-white font-ques bg-opacity-30 py-10 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
          <div>
            <h1 className="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl capitalize ">
              {heading}
            </h1>
            <p className="mt-4 capitalize text-base text-black lg:mt-8 sm:text-xl">
              {subHeading}
            </p>

            <a
              href="#"
              title=""
              className="inline-flex items-center px-6 py-4 mt-8 font-semibold text-black transition-all duration-200 bg-yellow-300 rounded-full lg:mt-16 hover:bg-yellow-400 focus:bg-yellow-400"
              role="button"
            >
              Shop Now
              <MdArrowCircleRight className="w-6 h-6 ml-8 -mr-2" />
              
            </a>
          </div>

          <div>
            <img
              className="w-full h-full"
              src={heroImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
