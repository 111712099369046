import React from "react";

function Testimonials() {
  return (
    <section id="testimonials" className="font-ques py-10 bg-gray-100 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">
            Trusted by <span className="text-main">1K+</span> Customers
          </h2>
        </div>

        <div className="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-20 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “I recently purchased a pair of shoes from this store, and I couldn't be happier with my purchase. The staff helped me find the right size and style, and the shoes have held up well over time. I would definitely recommend this store to anyone looking for high-quality clothing and shoes.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">
                Suraj Katwal
              </p>
              <p className="mt-1 text-base text-gray-600">
                Digital Marketer
              </p>
            </div>
          </div>

          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “I love shopping at this clothing store! They have a great selection of high-quality, branded clothing at affordable prices. The staff is always friendly and helpful, and I always leave feeling satisfied with my purchases.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">Aastha Sitaula</p>
              <p className="mt-1 text-base text-gray-600">Student</p>
            </div>
          </div>

          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “As someone who doesn't enjoy shopping, I was pleasantly surprised by my experience at this clothing store. The staff made me feel comfortable and helped me find the perfect outfit for an upcoming event. I even received compliments on my outfit throughout the night”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">
                Krishna Sitaula
              </p>
              <p className="mt-1 text-base text-gray-600">Retail Seller</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
