import React from "react";

function Services() {
  const elements = [
    {
      id: 1,
      name: "Wide Selection of Brands",
      content:
        "We import clothing from a variety of well-known and respected brands, so you can be sure that you're getting high-quality, stylish clothing that meets your needs and preferences.",
    },
    {
      id: 2,
      name: "Size and Fit Options",
      content:
        "We offer a range of sizes and fits to ensure that you find the perfect fit for your body type and style. Our knowledgeable staff can help you find the right size and fit, and we also offer alterations to ensure that your clothing fits perfectly.",
    },
    {
      id: 3,
      name: " Online Shopping",
      content:
        "Can't make it into our physical store? No problem! We also offer online shopping, so you can browse our selection of branded clothing from the comfort of your own home. Our website is easy to use and navigate, and we offer fast and reliable shipping.",
    },
    {
      id: 4,
      name: "Trendy Styles",
      content:
        "We offer a wide range of trendy styles, so you can be sure that you'll find something that suits your personal style. Whether you're looking for something casual or formal, we have a variety of options to choose from.",
    },
  ];
  return (
    <section id="services" className="font-ques py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center pb-8 space-y-3">
        <h1 className="text-3xl md:text-4xl uppercase font-bold text-center">
          Services
        </h1>
        <p className="w-28 h-3 bg-main"></p>
      </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 space-y-6 md:space-y-0">
          {elements.map(function (element) {
            const { id, name, content } = element;
            return (
              <div key={id} className="flex flex-col space-y-4 justify-center p-4 rounded-xl border-2 hover:border-main shadow-lg transition">
                <p className="text-xl font-semibold capitalize">{name}</p>
                <p>
                  {content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
