import React from "react";
const contact = "+977-9803692527";

function Cta() {
  return (
    <div className="font-ques">
      <section className="py-16 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="mb-5 text-3xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight  capitalize">
              Feel free to contact us for any queries you have
            </h2>
            <p className="mb-7 text-lg text-gray-600 font-medium">
            We're here to help! If you have any questions about our products or services, please don't hesitate to reach out to us. Our friendly and knowledgeable staff are always available to assist you and provide any information you need. You can contact us through our website, by phone, or by visiting our store in person. We look forward to hearing from you!
            </p>
            <div className="mb-11 md:inline-block">
              <button
                className="uppercase tracking-wider py-4 px-6 w-full font-semibold border border-main rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-main"
                type="button"
              >
                <a className="text-lg tracking-wide" href={`tel:${contact}`}>{contact}</a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cta;
