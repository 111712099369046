import React from "react";
import about from "../assets/about.jpg";
function About() {
  return (
    <section className="font-ques py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          <div className="relative lg:mb-12">
            <img
              className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
              alt=""
            />
            <div className="pl-12 pr-6">
              <img
                className="relative"
                src={about}
                alt=""
              />
            </div>
            <div className="absolute left-0 pr-12 bottom-8 xl:bottom-20">
              <div className="max-w-xs bg-blue-600 rounded-lg sm:max-w-md xl:max-w-md">
                <div className="px-3 py-4 sm:px-5 sm:py-8">
                  <div className="flex items-start">
                    <p className="text-3xl sm:text-4xl">👋</p>
                    <blockquote className="ml-5">
                      <p className="text-sm font-medium text-white sm:text-lg">
                        “I recently visited this clothing store, and I must say, I was blown away by the exceptional service I received. From the moment I walked in, I was greeted warmly by the staff, who were incredibly knowledgeable and helpful. They listened to my needs and preferences, and helped me find the perfect outfit for a special occasion.”
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="2xl:pl-16">
            <h2 className="text-3xl font-bold capitalize leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              We provide the best possible service
            </h2>
            <p className="text-xl leading-relaxed text-gray-900 mt-9">
            As a clothing store, we pride ourselves on providing the best possible service to our valued customers. Our commitment to exceptional service begins with our knowledgeable and friendly staff, who are always ready to assist you in finding the perfect outfit or accessory.
            </p>
            <p className="mt-6 text-xl leading-relaxed text-gray-900">
            We also believe in offering high-quality products that are both stylish and practical. We carefully curate our selection of clothing and accessories to ensure that we offer the latest trends and classic styles that are durable, comfortable, and affordable. From casual wear to formal attire, we have everything you need to look and feel your best.
            </p>
            <br />
            <a href="#" className="bg-main px-6 py-3 rounde">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
